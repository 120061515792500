import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

import SupportService from '../../../components/SupportService/SupportService.js';
import Notifications from "../../../components/Notifications/Notifications.js";

import { ROUTES } from "../../../services/navigation/navigation.routes.js";
import { getCookie } from "../../../services/util-service/util.service.js";
import { onEventGtag, useGtag } from "../../../hook/useGtag.js";

const YURL = 'yurl'; // Cookie from backend, Yoti URL

const AvsHome = () => {
  const [isYotiOnline, setIsYotiOnline] = useState(false);
  const siteName = process.env.REACT_APP_SITE_NAME;
  const { t } = useTranslation();

  useEffect(() => {
    const yotiLink = getYurlCookie();
    if (yotiLink) setIsYotiOnline(true);
  }, []);

  useGtag({
    data: {
      event: 'page_view',
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      event_label: 'home_page_loaded',
      event_category: 'age_verification'
    }
  });

  const getYurlCookie = () => {
    const yurlCookie = getCookie(YURL); // decoded string or '-1'
    let decodeUrl = false;

    if (yurlCookie) {
      try {
        decodeUrl = decodeURIComponent(yurlCookie);
        decodeUrl = window.atob(decodeUrl);
        onEventGtag({ data: { event: 'age-verification', verification_stage: 'decoding-success' } });
      } catch (err) {
        console.error('Decoding failed:', err);
        onEventGtag({ data: { event: 'age-verification', verification_stage: 'decoding-fail' } });
      }
    } else {
      onEventGtag({ data: { event: 'age-verification', verification_stage: 'link-not-found' } });
      console.log('No Yoti link found!');
    }

    if (decodeUrl === '-1') {
      return false;
    }
    return decodeUrl;
  };

  const goToYoti = () => {
    const yotiLink = getYurlCookie();

    if (yotiLink && yotiLink?.startsWith('https:')) {
      window.open(yotiLink, '_self');
    }
  };

  return (
    <>
      <div className="Content">
        <p>Hey there! It looks like you are from a state or region that requires age verification to access and view adult content.</p>
        <p>
          In order to provide you with the entertainment you are looking for, {siteName} has partnered with {" "}
          <a href="https://www.yoti.com/" rel="noopener noreferrer" className="Link" target="_blank">Yoti</a>
          {" "} age verification technology. Please verify that you meet the minimum age requirement to access and view adult content as defined by the laws in your state or region.
        </p>

        <div className="Grid">
          <div>
            <br />
            <span>Already a Member of Treasure Island Media? Click <a href="https://treasureislandmedia.com/members/login" className="Link">HERE</a> to Login.</span>
            <br />
          </div>
          <div>
            <h3>Need to verify age?</h3>
            {isYotiOnline ?
              <button className="VerifyAgeButton" onClick={goToYoti}>
                Click here to verify age
              </button>
              : <p>Our system is currently down, please try again later. Thank you.</p>
            }
          </div>
        </div>

        <p>Contact your legislators through the <a href="https://action.freespeechcoalition.com/age-verification-bills/"
          rel="noopener noreferrer"
          className="Link"
          target="_blank">Free Speech Coalition</a>.
        </p>

        <Link
          className="Link"
          to={ROUTES.ageVerificationFaq}>Why am I seeing this?
        </Link>
      </div>

      <SupportService />

      <div className="AvsLinksContainer">
        <Link
          className="Link"
          to={ROUTES.ageVerificationFaq}>{t('AvailableOptions.faqSupport')}
        </Link>
        <Link
          className="Link"
          to={ROUTES.ageVerificationTos}>{t('AvailableOptions.termsOfUse')}
        </Link>
        <Link
          className="Link"
          to={ROUTES.ageVerificationPrivacy}>{t('AvailableOptions.privacyPolicy')}
        </Link>
      </div>

      <Notifications />
    </>
  );
};

AvsHome.displayName = "AvsHome";

export default AvsHome;